<template>
  <ca-card class="mb-3">
    <template #header>
      <h4 class="mb-0">
        Fahrzeug
      </h4>
    </template>
    <template #body>
      <form
        novalidate
        @submit.prevent="save"
      >
        <div class="form-row my-3">
          <div class="col-12 col-md-6">
            <basic-typeahead
              id="licensePlate-country-char"
              v-model="car.country.char"
              name="Land / Kennzeichen"
              :columns="{label: ['col-12', 'col-md-8'], input:['col-12', 'col-md-4']}"
              placeholder="Land / Kennzeichen"
              :horizontal="true"
              label="Land / Kennzeichen"
              :margin="false"
              :query="searchCountry"
              :input-formatter="country => country.char.toString()"
              :result-formatter="countryResultFormatter"
              @hit="setCountry"
            />
          </div>
          <div class="col-12 col-md-6">
            <basic-input
              id="licensePlate"
              v-model="car.licensePlate"
              :margin="false"
              placeholder="Kennzeichen"
            />
          </div>
        </div>

        <div class="form-row my-3">
          <div class="col-12 col-md-8">
            <basic-select
              id="saisonkennzeichenvon"
              v-model="car.saisonkennzeichenvon"
              :columns="{label: ['col-12', 'col-md-6'], input:['col-12', 'col-md-6']}"
              placeholder="Von"
              :horizontal="true"
              :margin="false"
              label="Saisonkennzeichen von / bis"
              :options="monthOptions"
            />
          </div>
          <div class="col-12 col-md-4">
            <basic-select
              id="saisonkennzeichenbis"
              v-model="car.saisonkennzeichenbis"
              :margin="false"
              placeholder="Bis"
              :options="monthOptions"
            />
          </div>
        </div>

        <div
          v-if="!isHsnTsnDisabled"
          class="form-row my-3"
        >
          <div class="col-12 col-md-8">
            <basic-input
              v-model="car.hsn"
              data-test="car-form-hsn"
              :columns="{label: ['col-12', 'col-md-6'], input:['col-12', 'col-md-6']}"
              placeholder="HSN"
              name="HSN"
              :disabled="extend"
              :horizontal="true"
              :margin="false"
              label="Hersteller / Typ"
              :required="(!car.noHsnTsn && !isHsnTsnDisabled)"
              @input="$set(car, 'hsn', $event.toUpperCase())"
            />
          </div>
          <div class="col-12 col-md-4">
            <basic-input
              v-model="car.tsn"
              data-test="car-form-tsn"
              :margin="false"
              :disabled="extend"
              placeholder="TSN"
              name="TSN"
              :required="(!car.noHsnTsn && !isHsnTsnDisabled)"
              @input="$set(car, 'tsn', $event.toUpperCase())"
            />
          </div>
        </div>

        <basic-checkbox
          v-if="!isHsnTsnDisabled"
          v-model="car.noHsnTsn"
          :disabled="extend"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          label="keine HSN / TSN vorhanden"
        />

        <basic-input
          :value="car.producer"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Hersteller"
          :horizontal="true"
          label="Hersteller"
          :required="isHsnTsnDisabled || car.noHsnTsn"
          :disabled="(!isHsnTsnDisabled && !car.noHsnTsn) || extend"
          @input="$set(car, 'producer', $event.toUpperCase())"
        />

        <basic-input
          v-if="!car.noHsnTsn"
          :value="car.type"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Typ"
          :horizontal="true"
          label="Typ"
          :disabled="!isHsnTsnDisabled || extend"
          :required="isHsnTsnDisabled"
          @input="$set(car, 'type', $event.toUpperCase())"
        />

        <basic-input
          v-if="!isHsnTsnDisabled"
          :value="car.typePolice"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Typ (Zusatz)"
          :horizontal="true"
          name="Typ (Zusatz)"
          :label="'Typ' + (!car.noHsnTsn ? ' (Zusatz)' : '')"
          :required="car.noHsnTsn"
          :disabled="extend"
          @input="$set(car, 'typePolice', $event.toUpperCase())"
        />

        <basic-input
          :value="car.chassi"
          data-test="car-form-chassi"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="XXXXXXXXXXXXXXXXX"
          :horizontal="true"
          :validation="{
            min: 17,
            max: 17
          }"
          maxlength="17"
          label="Fahrgestell Nummer"
          :disabled="extend"
          required
          input-prevent-default
          @input="sanitizeChassi"
        >
          <template #small>
            <small>Bitte die 17 stellige Fahrgestellnummer eingeben.</small>
            <small
              v-if="chassiInputManipulated"
              class="text-danger"
            ><br>Die FIN darf nur aus arabischen  Ziffern und lateinischen Buchstaben, außer I, O und Q, bestehen</small>
          </template>
        </basic-input>
        <div class="form-row my-3">
          <div class="col-12 col-md-8">
            <basic-datepicker
              v-model="car.registrationDate"
              data-test="car-form-registration-date"
              :columns="{label: ['col-12', 'col-md-6'], input:['col-12', 'col-md-6']}"
              :horizontal="true"
              required
              label="Erstzulassung"
              :disabled="extend"
              placeholder="TT.MM.JJJJ"
              :margin="false"
            />
          </div>
          <div class="col-12 col-md-4">
            <basic-input
              v-model="car.monthFromRegistrationDate"
              :margin="false"
              placeholder="Alter in Monaten"
              disabled
            >
              <template #append>
                <span class="input-group-text">Monat(e)</span>
              </template>
            </basic-input>
          </div>
        </div>
        <div class="form-row my-3">
          <div class="col-12 col-md-8">
            <basic-input
              v-model="car.ccm"
              :columns="{label: ['col-12', 'col-md-6'], input:['col-12', 'col-md-6']}"
              :horizontal="true"
              required
              label="Hubraum/kW"
              :disabled="(!car.noHsnTsn && !isHsnTsnDisabled) || extend"
              placeholder="ccm"
              :margin="false"
              type="number"
            >
              <template #append>
                <span class="input-group-text">ccm</span>
              </template>
            </basic-input>
          </div>
          <div class="col-12 col-md-4">
            <basic-input
              v-model="car.kw"
              :margin="false"
              required
              placeholder="kW"
              :disabled="(!car.noHsnTsn && !isHsnTsnDisabled) || extend"
              type="number"
            >
              <template #append>
                <span class="input-group-text">kW</span>
              </template>
            </basic-input>
          </div>
        </div>

        <basic-datepicker
          v-model="car.milageMeasureDate"
          data-test="car-form-km-measure-date"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          label="km abgelesen am"
          placeholder="TT.MM.JJJJ"
          required
        />

        <basic-input
          v-model="car.km"
          data-test="car-form-km"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          placeholder="km Stand"
          label="km Stand"
          :validation="{ regex: /^[0-9]+$/ }"
          required
        >
          <template #append>
            <span class="input-group-text">km</span>
          </template>
        </basic-input>

        <BasicCurrencyInput
          v-if="!isKaufschutz"
          v-model="car.price"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          placeholder="Kaufpreis"
          label="Kaufpreis"
        >
          <template #append>
            <span class="input-group-text">€</span>
          </template>
        </BasicCurrencyInput>

        <basic-select
          v-model="car.fuel"
          data-test="car-form-fuel"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Kraftstoff"
          :horizontal="true"
          label="Kraftstoff"
          required
          :options="[
            {label: 'Benzin', value: 'Benzin'},
            {label: 'Diesel', value: 'Diesel'},
            {label: 'Elektro', value: 'Elektro'},
            {label: 'Hybrid', value: 'Hybrid'},
            {label: 'Erdgas', value: 'Erdgas'},
            {label: 'LPG', value: 'LPG'},
          ]"
        />

        <basic-checkbox
          v-if="!isKaufschutz"
          v-model="car.gasUpcharge"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          :label="upChargeLabel"
        />

        <basic-select
          v-model="car.usage"
          data-test="car-form-usage"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Nutzungsart"
          :horizontal="true"
          label="Nutzungsart"
          required
          :options="[
            {label: 'ausschließlich gewerblich', value: 'ausschließlich gewerblich'},
            {label: 'ausschließlich privat', value: 'ausschließlich privat'},
            {label: 'überwiegend gewerblich', value: 'überwiegend gewerblich'},
            {label: 'überwiegend privat', value: 'überwiegend privat'},
          ]"
        />
      </form>
    </template>
  </ca-card>
</template>

<script>
import BasicInput from '@/components/BaseComponents/BasicInput'
import BasicSelect from '@/components/BaseComponents/BasicSelect'
import BasicDatepicker from '@/components/BaseComponents/BasicDatepicker'
import BasicCheckbox from '@/components/BaseComponents/BasicCheckbox'
import BasicTypeahead from '@/components/BaseComponents/BasicTypeahead'
import BasicCurrencyInput from '@/components/BaseComponents/BasicCurrencyInput'
import CaCard from '@/components/Card'
import debounce from 'lodash.debounce'
import moment from 'moment'
import SanitizeChassi from '../../../helpers/sanitizeChassi'

export default {
  inject: ['$validator'],
  name: 'CarForm',
  components: {
    BasicInput,
    BasicSelect,
    BasicDatepicker,
    BasicCheckbox,
    BasicTypeahead,
    BasicCurrencyInput,
    CaCard
  },
  props: {
    car: {
      type: Object,
      required: true
    },
    extend: {
      type: Boolean,
      default: false
    },
    edit: {
      type: Boolean,
      default: false
    },
    isKaufschutz: {
      type: Boolean,
      default: false
    },
    hsnTsnDisabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    monthOptions: [
      { value: null },
      { label: 'Januar', value: 1 },
      { label: 'Februar', value: 2 },
      { label: 'März', value: 3 },
      { label: 'April', value: 4 },
      { label: 'Mai', value: 5 },
      { label: 'Juni', value: 6 },
      { label: 'Juli', value: 7 },
      { label: 'August', value: 8 },
      { label: 'September', value: 9 },
      { label: 'Oktober', value: 10 },
      { label: 'November', value: 11 },
      { label: 'Dezember', value: 12 }
    ],
    chassiInputManipulated: false
  }),
  computed: {
    isHsnTsnDisabled () {
      return this.hsnTsnDisabled || this.$system === 'at'
    },
    upChargeLabel () {
      if (this.$system === 'at') return 'Aufpreis für Hybrid, Plugin-Hybrid, Erdgas, LPG (+30%)'
      else return 'Aufpreis für Erdgas, LPG (+30%)'
    }
  },
  watch: {
    'car.hsn': {
      immediate: true,
      handler: 'loadHsnTsn'
    },
    'car.tsn': {
      immediate: true,
      handler: 'loadHsnTsn'
    },
    'car.registrationDate': {
      immediate: true,
      handler: 'setMonthFromRegistrationDate'
    }
  },
  methods: {
    debouncedLoadHsnTsn: debounce(async function (hsn, tsn) {
      if (hsn && tsn) {
        const hsnTsnRequest = await this.$store.dispatch('hsntsns/find', {
          query: {
            hsn,
            tsn
          }
        })
        const matchingCar = hsnTsnRequest.data[0]
        if (matchingCar) {
          this.$set(this.car, 'type', matchingCar.typ)
          this.$set(this.car, 'ccm', matchingCar.ccm)
          this.$set(this.car, 'kw', matchingCar.kw)
          this.$set(this.car, 'producer', matchingCar.producer)
        }
      }
    }, 500),
    async loadHsnTsn () {
      const { hsn, tsn } = this.car
      if (!this.edit && !this.isHsnTsnDisabled && !this.extend) {
        this.$delete(this.car, 'type')
        this.$delete(this.car, 'ccm')
        this.$delete(this.car, 'kw')
        this.$delete(this.car, 'producer')
      }
      if (!this.isHsnTsnDisabled) {
        this.debouncedLoadHsnTsn(hsn, tsn)
      }
    },
    setMonthFromRegistrationDate (registrationDate) {
      if (registrationDate) {
        let diff = moment().diff(registrationDate, 'months', false)
        if (diff != null) {
          if (diff < 0) diff = 0
          const monthFromRegistrationDate = diff + 1
          this.$set(this.car, 'monthFromRegistrationDate', monthFromRegistrationDate)
        };
      }
    },
    searchCountry (char) {
      return this.$store.dispatch('countries/find', {
        query: {
          $limit: 10, char: char.toUpperCase()
        }
      })
    },
    countryResultFormatter (country) {
      return `${country.char} ${country.name}`
    },
    setCountry (country) {
      this.car.country = {
        ...country
      }
    },
    sanitizeChassi (chassi) {
      const sanitized = SanitizeChassi(chassi)
      this.chassiInputManipulated = sanitized.manipulated
      this.$set(this.car, 'chassi', sanitized.chassi)
    }
  }
}
</script>
