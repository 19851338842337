<template>
  <div v-if="loading">
    Lädt...
  </div>
  <div v-else>
    <ca-header heading="Fahrzeug berarbeiten">
      <template #alert>
        <ca-alert
          v-if="!!saveErrorMessage"
          variant="danger"
          :message="saveErrorMessage"
          @close="saveErrorMessage = null"
        />
        <ca-alert
          v-if="showFormInvalidHint"
          variant="info"
          message="Es sind noch nicht alle erforderlichen Felder ausgefüllt"
          @close="showFormInvalidHint = null"
        />
      </template>
      <template #buttonSpace>
        <button
          class="btn btn-success text-white"
          type="button"
          :disabled="savePending"
          @click="save()"
        >
          <span v-if="!savePending">Speichern</span>
          <i
            v-if="savePending"
            class="far fa-spin fa-circle-notch"
          />
        </button>
      </template>
    </ca-header>
    <div class="container-fluid px-4 pt-4">
      <div class="row">
        <div class="col-12 col-xl-8 col-xxl-6">
          <car-form
            class="mb-3"
            :car="car"
          />
          <template v-if="carAdditionalInfo">
            <CarAdditionalInfoStep1 :additional-data="carAdditionalInfo" />
            <CarAdditionalInfoStep2 :additional-data="carAdditionalInfo" />
            <CarAdditionalInfoStep3 :additional-data="carAdditionalInfo" />
            <CarAdditionalInfoStep4 :additional-data="carAdditionalInfo" />
            <CarAdditionalInfoStep5 :additional-data="carAdditionalInfo" />
          </template>

          <dev-info :data="{car}" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DevInfo from '@/components/DevInfo'
import CaHeader from '@/components/Layout/Header'
import CarForm from '@/components/Forms/Order/CarForm.vue'
import CarAdditionalInfoStep1 from '@/components/CarAdditionalData/Step1'
import CarAdditionalInfoStep2 from '@/components/CarAdditionalData/Step2'
import CarAdditionalInfoStep3 from '@/components/CarAdditionalData/Step3'
import CarAdditionalInfoStep4 from '@/components/CarAdditionalData/Step4'
import CarAdditionalInfoStep5 from '@/components/CarAdditionalData/Step5'
export default {
  $_veeValidate: {
    validator: 'new' // give me my own validator scope.
  },
  name: 'CarEdit',
  components: {
    DevInfo,
    CaHeader,
    CarForm,
    CarAdditionalInfoStep1,
    CarAdditionalInfoStep2,
    CarAdditionalInfoStep3,
    CarAdditionalInfoStep4,
    CarAdditionalInfoStep5
  },
  data: () => ({
    savePending: false,
    saveError: null,
    loading: false,
    saveErrorMessage: null,
    showFormInvalidHint: false,
    car: {},
    carAdditionalInfo: null
  }),
  watch: {
    $route: {
      immediate: true,
      handler: 'fetchData'
    }
  },
  methods: {
    async save () {
      try {
        const valid = await this.$validator.validateAll()
        if (this.savePending) {
          return
        }
        if (!valid) {
          this.showFormInvalidHint = true
          return
        }
        this.saveErrorMessage = null
        this.showFormInvalidHint = false
        this.savePending = true
        await this.car.save()
        if (this.carAdditionalInfo) {
          await this.carAdditionalInfo.save()
        }
        this.$router.push({ name: 'CarDetail', params: { carId: this.car._id } })
      } catch (error) {
        console.error(error)
        this.saveError = error
      } finally {
        this.savePending = false
      }
    },
    async fetchData () {
      try {
        this.loading = true
        this.car = await this.$store.dispatch('car/get', this.$route.params.carId)
        this.carAdditionalInfo = this.car.carAdditionalInfo
        console.log(this.carAdditionalInfo)
      } catch (error) {
        this.saveErrorMessage = error
        console.error(error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
